/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import type { ReactNode } from 'react';
import { useEffect, useReducer } from 'react';

import { searchReducer } from './reducers';
import { SearchStateContext, getAllowedParams, initialValues, prepareFilters } from './utils';
import { useLocation, useSearchParams } from 'react-router-dom';
import { SearchParamsFiltersURLInterface, SearchParamsInterface } from '@app/models/search';
import { localStorageHandler } from '@app/utils/utils';
import { useUser } from '../user';
import { isEqual } from 'lodash';

export const SearchProvider = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  // @ts-ignore
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, dispatch] = useReducer(searchReducer, initialValues);
  const { user } = useUser();
  const { results, fetchResults, searchParams: searchParamsContext, filters } = state;

  const value = {
    searchParams: searchParamsContext,
    results,
    searchDispatch: dispatch,
    fetchResults,
    filters,
  };

  const handleLocalSuggestions = ({
    location,
    placeID,
    lat,
    lng,
  }: {
    location?: string;
    placeID?: string;
    lat?: number;
    lng?: number;
  }) => {
    const localSuggestions = localStorageHandler.get('dSearchSuggestions');
    if (!localSuggestions) {
      localStorageHandler.set(
        'dSearchSuggestions',
        JSON.stringify(
          location ? [{ location: location, placeID: placeID, searchLatitude: lat, searchLongitude: lng }] : [],
        ),
      );
    } else {
      const parsedLocal = JSON.parse(localSuggestions);
      if (location && !parsedLocal.some((o: { placeID: string }) => o.placeID === placeID)) {
        localStorageHandler.set(
          'dSearchSuggestions',
          JSON.stringify(
            [{ location: location, placeID: placeID, searchLatitude: lat, searchLongitude: lng }, ...parsedLocal].slice(
              0,
              5,
            ),
          ),
        );
      }
    }
  };
  const handleLocalParams = (newParams?: Partial<SearchParamsInterface>) => {
    const localParams = localStorageHandler.get('dSearchParams');

    if (localParams) {
      const parsedLocalParams = JSON.parse(localParams);
      const searchUpdated = { ...parsedLocalParams, ...newParams, userId: String(user?.id) ?? 'guest' };

      if (searchParamsContext && isEqual(parsedLocalParams, searchParamsContext)) return;

      localStorageHandler.set('dSearchParams', JSON.stringify(searchUpdated));

      dispatch({ type: 'updateSearchParams', updateSearchParams: searchUpdated });
    } else {
      const newSearch = { ...newParams, userId: String(user?.id) ?? 'guest' };
      localStorageHandler.set('dSearchParams', JSON.stringify(newSearch));
      dispatch({ type: 'updateSearchParams', updateSearchParams: newSearch });
    }
  };

  const refreshOrFetch = (extra?: string | SearchParamsFiltersURLInterface) => {
    const searchParamsObject = {
      ...(getAllowedParams(searchParamsContext, true) as SearchParamsInterface),
      ...(extra as SearchParamsFiltersURLInterface),
    };

    setSearchParams(searchParamsObject as unknown as string);

    dispatch({ type: 'setFetching', fetching: false });
  };

  useEffect(() => {
    if (searchParamsContext) {
      handleLocalParams(searchParamsContext);

      handleLocalSuggestions({
        location: searchParamsContext?.location,
        placeID: searchParamsContext?.placeID,
        lat: searchParamsContext.searchLatitude,
        lng: searchParamsContext.searchLongitude,
      });
    }

    if (fetchResults) {
      refreshOrFetch(prepareFilters(filters, !location.pathname.includes('results')));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchResults, searchParamsContext, filters]);

  useEffect(() => {
    handleLocalParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <SearchStateContext.Provider value={value}>{children}</SearchStateContext.Provider>;
};
