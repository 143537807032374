import { FeedbackResponseInterface } from '@app/models/feedback';
import { BaseModal } from '../BaseModal';
import { Select } from '../Fields';
import { HookedForm } from '../HookedForm';
import { ReviewCard } from '../ReviewCard';
import SortIcon from '@app/assets/icons/sort.svg?react';
import { format } from 'date-fns';
import { UseFormReturn } from 'react-hook-form';

interface ReviewsModalProps {
  showModal: boolean;
  handleModal: () => void;
  hotelReviews?: FeedbackResponseInterface;
  feedbackForm: UseFormReturn<{ orderBy: { label: string; value: string } }>;
}
export const ReviewsModal = ({ showModal, handleModal, hotelReviews, feedbackForm }: ReviewsModalProps) => {
  return (
    <BaseModal header="Guest reviews" size="max-w-5xl" open={showModal} onClose={handleModal}>
      <div className="text-left space-y-4">
        <div className="flex justify-between">
          <div className="rounded-full bg-white flex  py-2 gap-4">
            <div className="flex flex-col">
              <span className="font-bold">Very Good</span>
              <span className="text-sm text-dorrus-text-secondary">
                {hotelReviews?.feedbacks?.length} review{hotelReviews?.feedbacks?.length ?? 0 > 1 ? 's' : ''}
              </span>
            </div>
            <div className="w-10 h-10 bg-dorrus-background-secondary flex items-center justify-center text-white rounded-xl">
              {hotelReviews?.hotelRating?.toFixed(1)}
            </div>
          </div>
          <HookedForm mode="onChange" formHook={feedbackForm} className="w-fit">
            <Select
              icon={<SortIcon width={15} />}
              placeholder="Sort by"
              name="orderBy"
              triggerClassname="border-0  bg-white !min-w-28 !rounded-full !px-4 !py-2 text-center"
              options={[
                { label: 'Rating: Highest', value: 'HIGHEST_RATING' },
                { label: 'Rating: Lowest', value: 'LOWEST_RATING' },
                { label: 'Most Recent', value: 'NEWEST' },
              ]}
            />
          </HookedForm>
        </div>
        {hotelReviews?.feedbacks && hotelReviews?.feedbacks?.length > 0 && (
          <div className="grid grid-cols-12 gap-10">
            <div className="col-span-5">
              <div className="bg-white rounded-3xl ">
                <div className="flex flex-col gap-2">
                  <div className="flex justify-between items-center">
                    <p>Cleanliness</p>
                    <div className="flex flex-row items-center gap-4">
                      <span className="min-w-[100px] bg-black/50 block w-full h-1  rounded-full overflow-hidden">
                        <span
                          style={{
                            width: `${Math.floor((Number(hotelReviews?.avgClean ?? 0) / 10) * 100)}%`,
                          }}
                          className="bg-black h-full block"
                        ></span>
                      </span>
                      <p className="font-bold">{hotelReviews?.avgClean}</p>
                    </div>
                  </div>
                  <div className="flex justify-between items-center">
                    <p>Accuracy</p>
                    <div className="flex flex-row items-center gap-4">
                      <span className="min-w-[100px] bg-black/50 block w-full h-1  rounded-full overflow-hidden">
                        <span
                          style={{
                            width: `${Math.floor((Number(hotelReviews?.avgAccuracy ?? 0) / 10) * 100)}%`,
                          }}
                          className="bg-black h-full block"
                        ></span>
                      </span>
                      <p className="font-bold">{hotelReviews?.avgAccuracy}</p>
                    </div>
                  </div>
                  <div className="flex justify-between items-center">
                    <p>Communication</p>
                    <div className="flex flex-row items-center gap-4">
                      <span className="min-w-[100px] bg-black/50 block w-full h-1  rounded-full overflow-hidden">
                        <span
                          style={{
                            width: `${Math.floor((Number(hotelReviews?.avgCommunication ?? 0) / 10) * 100)}%`,
                          }}
                          className="bg-black h-full block"
                        ></span>
                      </span>
                      <p className="font-bold">{hotelReviews?.avgCommunication}</p>
                    </div>
                  </div>
                  <div className="flex justify-between items-center">
                    <p>Location</p>
                    <div className="flex flex-row items-center gap-4">
                      <span className="min-w-[100px] bg-black/50 block w-full h-1  rounded-full overflow-hidden">
                        <span
                          style={{
                            width: `${Math.floor((Number(hotelReviews?.avgLocation ?? 0) / 10) * 100)}%`,
                          }}
                          className="bg-black h-full block"
                        ></span>
                      </span>
                      <p className="font-bold">{hotelReviews?.avgLocation}</p>
                    </div>
                  </div>
                  <div className="flex justify-between items-center">
                    <p>Check-in</p>
                    <div className="flex flex-row items-center gap-4">
                      <span className="min-w-[100px] bg-black/50 block w-full h-1  rounded-full overflow-hidden">
                        <span
                          style={{
                            width: `${Math.floor((Number(hotelReviews?.avgCheckIn ?? 0) / 10) * 100)}%`,
                          }}
                          className="bg-black h-full block"
                        ></span>
                      </span>
                      <p className="font-bold">{hotelReviews?.avgCheckIn}</p>
                    </div>
                  </div>
                  <div className="flex justify-between items-center">
                    <p>Value</p>
                    <div className="flex flex-row items-center gap-4">
                      <span className="min-w-[100px] bg-black/50 block w-full h-1  rounded-full overflow-hidden">
                        <span
                          style={{
                            width: `${Math.floor((Number(hotelReviews?.avgValue ?? 0) / 10) * 100)}%`,
                          }}
                          className="bg-black h-full block"
                        ></span>
                      </span>
                      <p className="font-bold">{hotelReviews?.avgValue}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-7 flex flex-col gap-4 max-h-[500px] overflow-y-scroll">
              {hotelReviews?.feedbacks.map((feedback, index) => (
                <ReviewCard
                  disablePadding
                  disableShowMore
                  key={index}
                  id={String(feedback.id)}
                  date={format(feedback.createdUTC, 'MMM dd, yyyy')}
                  fullName={feedback?.createdBy}
                  rating={(
                    (feedback?.accuracy +
                      feedback?.checkIn +
                      feedback?.cleanLines +
                      feedback?.communication +
                      feedback?.location +
                      feedback?.value / 6) /
                    10
                  ).toFixed(1)}
                  review={feedback.note?.slice(0, 250) ?? ''}
                  room={feedback?.order?.orderItems?.[0]?.services?.[0]?.lodgingRooms?.[0]?.roomName}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </BaseModal>
  );
};
